function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Head from 'next/head';
// import Image from 'next/image'
import Image from 'next/legacy/image';
// import Particles from "react-tsparticles";
import Slider from "react-slick";
import HeroSection from './../components/HeroSection';
import PlanesSection from './../components/Planes';
// import TestimoniosSection from './../components/Testimonios';
// import GlobalisationImage from '../public/images/globalisation.jpg'
import ContactoSection from './../components/Contacto';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SimpleSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 6000,
    pauseOnHover: true
  };
  return _jsxs(Slider, {
    ...settings,
    children: [_jsx("div", {
      children: _jsx("div", {
        className: "map-responsive",
        children: _jsx("iframe", {
          src: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31414.645191016567!2d-67.44795157011148!3d10.194404508297545!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8021ea622669d7%3A0x5e2ed722c664e651!2sCagua%202122%2C%20Aragua!5e0!3m2!1ses!2sve!4v1658255357729!5m2!1ses!2sve",
          height: "450",
          style: {
            border: 0
          },
          allowfullscreen: "",
          loading: "lazy",
          referrerpolicy: "no-referrer-when-downgrade"
        })
      })
    }), _jsx("div", {
      children: _jsx("div", {
        className: "map-responsive",
        children: _jsx("iframe", {
          src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31418.176755870252!2d-67.57559844581029!3d10.15852402843063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e80180f8fc54e91%3A0xdb0a8120ba26a308!2sPalo%20Negro%2C%20Aragua!5e0!3m2!1ses!2sve!4v1658257547363!5m2!1ses!2sve",
          height: "450",
          style: {
            border: 0
          },
          allowfullscreen: "",
          loading: "lazy",
          referrerpolicy: "no-referrer-when-downgrade"
        })
      })
    }), _jsx("div", {
      children: _jsx("div", {
        className: "map-responsive",
        children: _jsx("iframe", {
          src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15705.7966196738!2d-67.55396606403049!3d10.225311800097506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e802315d0484049%3A0xa524e5a0fecbe37b!2sLa%20Morita%2C%20Turmero%2C%20Aragua!5e0!3m2!1ses!2sve!4v1658257967693!5m2!1ses!2sve",
          height: "450",
          style: {
            border: 0
          },
          allowfullscreen: "",
          loading: "lazy",
          referrerpolicy: "no-referrer-when-downgrade"
        })
      })
    }), _jsx("div", {
      children: _jsx("div", {
        className: "map-responsive",
        children: _jsx("iframe", {
          src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62814.871568924136!2d-67.64042991325165!3d10.267266480615591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e803c989377fe87%3A0xb5ff524dadae5b74!2sMaracay%2C%20Aragua!5e0!3m2!1ses!2sve!4v1658258050831!5m2!1ses!2sve",
          height: "450",
          style: {
            border: 0
          },
          allowfullscreen: "",
          loading: "lazy",
          referrerpolicy: "no-referrer-when-downgrade"
        })
      })
    }), _jsx("div", {
      children: _jsx("div", {
        className: "map-responsive",
        children: _jsx("iframe", {
          src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31413.412738794814!2d-67.57312789580084!3d10.206896769907603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8022d8f9831d5f%3A0xa788576553682145!2sSanta%20Rita%2C%20Aragua!5e0!3m2!1ses!2sve!4v1658258128695!5m2!1ses!2sve",
          height: "450",
          style: {
            border: 0
          },
          allowfullscreen: "",
          loading: "lazy",
          referrerpolicy: "no-referrer-when-downgrade"
        })
      })
    }), _jsx("div", {
      children: _jsx("div", {
        className: "map-responsive",
        children: _jsx("iframe", {
          src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62819.60954292352!2d-67.52639346327112!3d10.243380841164736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e802155c56a4ee5%3A0xb22e37eb965185f2!2sTurmero%2C%20Aragua!5e0!3m2!1ses!2sve!4v1658258176286!5m2!1ses!2sve",
          height: "450",
          style: {
            border: 0
          },
          allowfullscreen: "",
          loading: "lazy",
          referrerpolicy: "no-referrer-when-downgrade"
        })
      })
    })]
  });
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "17qne2n",
  styles: "margin-bottom:0.5rem;margin-top:2rem;text-align:center;font-size:2.25rem;line-height:2.5rem;font-weight:700;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity))"
} : {
  name: "131f8jw-Home",
  styles: "margin-bottom:0.5rem;margin-top:2rem;text-align:center;font-size:2.25rem;line-height:2.5rem;font-weight:700;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1d3w5wq",
  styles: "width:100%"
} : {
  name: "1c9tl6e-Home",
  styles: "width:100%;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1n3apwf",
  styles: "margin-left:auto;margin-right:auto;margin-top:0px;margin-bottom:0px;height:0.25rem;width:33.333333%;border-top-left-radius:0.25rem;border-top-right-radius:0.25rem;padding-top:0px;padding-bottom:0px;opacity:0.25"
} : {
  name: "bxdf0l-Home",
  styles: "margin-left:auto;margin-right:auto;margin-top:0px;margin-bottom:0px;height:0.25rem;width:33.333333%;border-top-left-radius:0.25rem;border-top-right-radius:0.25rem;padding-top:0px;padding-bottom:0px;opacity:0.25;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1gythhm",
  styles: "margin-left:auto;margin-right:auto;margin-top:0.75rem;max-width:56rem;text-align:center;font-size:1.125rem;line-height:1.75rem;--tw-text-opacity:1;color:rgb(107 114 128 / var(--tw-text-opacity))"
} : {
  name: "nhf63k-Home",
  styles: "margin-left:auto;margin-right:auto;margin-top:0.75rem;max-width:56rem;text-align:center;font-size:1.125rem;line-height:1.75rem;--tw-text-opacity:1;color:rgb(107 114 128 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1j389vi",
  styles: "font-weight:700"
} : {
  name: "188cwr-Home",
  styles: "font-weight:700;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "48ladc",
  styles: "margin-left:auto;margin-right:auto;display:flex;max-width:64rem;flex-direction:column;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity));@media (min-width: 768px){flex-direction:row;}"
} : {
  name: "tu29ax-Home",
  styles: "margin-left:auto;margin-right:auto;display:flex;max-width:64rem;flex-direction:column;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity));@media (min-width: 768px){flex-direction:row;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "17dx5yk",
  styles: "width:100%;text-align:center;@media (min-width: 768px){width:50%;}@media (min-width: 1024px){width:33.333333%;}"
} : {
  name: "1tp1tvs-Home",
  styles: "width:100%;text-align:center;@media (min-width: 768px){width:50%;}@media (min-width: 1024px){width:33.333333%;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "c3kw7m",
  styles: "transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transition-property:transform;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:300ms;:hover{--tw-scale-x:1.1;--tw-scale-y:1.1;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));}"
} : {
  name: "14rijpc-Home",
  styles: "transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transition-property:transform;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:300ms;:hover{--tw-scale-x:1.1;--tw-scale-y:1.1;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "1wff8xv",
  styles: "margin-bottom:0.75rem;margin-top:0.25rem;font-size:1.5rem;line-height:1;font-weight:700;--tw-text-opacity:1;color:rgb(31 41 55 / var(--tw-text-opacity))"
} : {
  name: "9vpgcm-Home",
  styles: "margin-bottom:0.75rem;margin-top:0.25rem;font-size:1.5rem;line-height:1;font-weight:700;--tw-text-opacity:1;color:rgb(31 41 55 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "1p5p7f0",
  styles: "--tw-text-opacity:1;color:rgb(75 85 99 / var(--tw-text-opacity))"
} : {
  name: "925af6-Home",
  styles: "--tw-text-opacity:1;color:rgb(75 85 99 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "1df95yl",
  styles: "width:100%;text-align:center;@media (min-width: 768px){width:50%;}@media (min-width: 1280px){width:33.333333%;}"
} : {
  name: "10oosvy-Home",
  styles: "width:100%;text-align:center;@media (min-width: 768px){width:50%;}@media (min-width: 1280px){width:33.333333%;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "c3kw7m",
  styles: "transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transition-property:transform;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:300ms;:hover{--tw-scale-x:1.1;--tw-scale-y:1.1;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));}"
} : {
  name: "14rijpc-Home",
  styles: "transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transition-property:transform;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:300ms;:hover{--tw-scale-x:1.1;--tw-scale-y:1.1;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "1wff8xv",
  styles: "margin-bottom:0.75rem;margin-top:0.25rem;font-size:1.5rem;line-height:1;font-weight:700;--tw-text-opacity:1;color:rgb(31 41 55 / var(--tw-text-opacity))"
} : {
  name: "9vpgcm-Home",
  styles: "margin-bottom:0.75rem;margin-top:0.25rem;font-size:1.5rem;line-height:1;font-weight:700;--tw-text-opacity:1;color:rgb(31 41 55 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "1p5p7f0",
  styles: "--tw-text-opacity:1;color:rgb(75 85 99 / var(--tw-text-opacity))"
} : {
  name: "925af6-Home",
  styles: "--tw-text-opacity:1;color:rgb(75 85 99 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "1df95yl",
  styles: "width:100%;text-align:center;@media (min-width: 768px){width:50%;}@media (min-width: 1280px){width:33.333333%;}"
} : {
  name: "10oosvy-Home",
  styles: "width:100%;text-align:center;@media (min-width: 768px){width:50%;}@media (min-width: 1280px){width:33.333333%;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "c3kw7m",
  styles: "transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transition-property:transform;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:300ms;:hover{--tw-scale-x:1.1;--tw-scale-y:1.1;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));}"
} : {
  name: "14rijpc-Home",
  styles: "transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transition-property:transform;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:300ms;:hover{--tw-scale-x:1.1;--tw-scale-y:1.1;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "1wff8xv",
  styles: "margin-bottom:0.75rem;margin-top:0.25rem;font-size:1.5rem;line-height:1;font-weight:700;--tw-text-opacity:1;color:rgb(31 41 55 / var(--tw-text-opacity))"
} : {
  name: "9vpgcm-Home",
  styles: "margin-bottom:0.75rem;margin-top:0.25rem;font-size:1.5rem;line-height:1;font-weight:700;--tw-text-opacity:1;color:rgb(31 41 55 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "1p5p7f0",
  styles: "--tw-text-opacity:1;color:rgb(75 85 99 / var(--tw-text-opacity))"
} : {
  name: "925af6-Home",
  styles: "--tw-text-opacity:1;color:rgb(75 85 99 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref19 = process.env.NODE_ENV === "production" ? {
  name: "bepbxe",
  styles: "display:grid;width:100%;place-items:center;background-size:cover;background-position:center;> *{grid-column-start:1;grid-row-start:1;}margin-top:1.25rem;background-attachment:fixed"
} : {
  name: "y0195n-Home",
  styles: "display:grid;width:100%;place-items:center;background-size:cover;background-position:center;> *{grid-column-start:1;grid-row-start:1;}margin-top:1.25rem;background-attachment:fixed;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref20 = process.env.NODE_ENV === "production" ? {
  name: "17zj75z",
  styles: "grid-column-start:1;grid-row-start:1;height:100%;width:100%;background-color:hsl(var(--n) / var(--tw-bg-opacity));--tw-bg-opacity:0.6"
} : {
  name: "739md-Home",
  styles: "grid-column-start:1;grid-row-start:1;height:100%;width:100%;background-color:hsl(var(--n) / var(--tw-bg-opacity));--tw-bg-opacity:0.6;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref21 = process.env.NODE_ENV === "production" ? {
  name: "1ih80pr",
  styles: "z-index:0;display:flex;align-items:center;justify-content:center;max-width:80rem;gap:1rem;padding:1rem;padding-top:2.5rem !important;padding-bottom:2.5rem !important;text-align:center;--tw-text-opacity:1;color:hsl(var(--nc) / var(--tw-text-opacity))"
} : {
  name: "1f3r0qy-Home",
  styles: "z-index:0;display:flex;align-items:center;justify-content:center;max-width:80rem;gap:1rem;padding:1rem;padding-top:2.5rem !important;padding-bottom:2.5rem !important;text-align:center;--tw-text-opacity:1;color:hsl(var(--nc) / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref22 = process.env.NODE_ENV === "production" ? {
  name: "qhxz92",
  styles: "max-width:100%"
} : {
  name: "18xfzid-Home",
  styles: "max-width:100%;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref23 = process.env.NODE_ENV === "production" ? {
  name: "7r7chi",
  styles: "display:flex;flex-direction:column;@media (min-width: 768px){flex-direction:row;}"
} : {
  name: "tg4u5k-Home",
  styles: "display:flex;flex-direction:column;@media (min-width: 768px){flex-direction:row;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref24 = process.env.NODE_ENV === "production" ? {
  name: "puuo7u",
  styles: "width:20rem;@media (min-width: 768px){width:50%;}@media (min-width: 1024px){width:50%;}"
} : {
  name: "s3l1p4-Home",
  styles: "width:20rem;@media (min-width: 768px){width:50%;}@media (min-width: 1024px){width:50%;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref25 = process.env.NODE_ENV === "production" ? {
  name: "3ihxzw",
  styles: "width:100%;padding:0.75rem;@media (min-width: 768px){width:50%;}@media (min-width: 1024px){width:50%;}"
} : {
  name: "4qziwd-Home",
  styles: "width:100%;padding:0.75rem;@media (min-width: 768px){width:50%;}@media (min-width: 1024px){width:50%;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref26 = process.env.NODE_ENV === "production" ? {
  name: "887g2k",
  styles: "margin-bottom:1.25rem;font-size:3rem;line-height:1;font-weight:700"
} : {
  name: "uvzg2j-Home",
  styles: "margin-bottom:1.25rem;font-size:3rem;line-height:1;font-weight:700;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref27 = process.env.NODE_ENV === "production" ? {
  name: "9kwmmn",
  styles: "margin-bottom:1.25rem"
} : {
  name: "1py2nyv-Home",
  styles: "margin-bottom:1.25rem;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref28 = process.env.NODE_ENV === "production" ? {
  name: "17qne2n",
  styles: "margin-bottom:0.5rem;margin-top:2rem;text-align:center;font-size:2.25rem;line-height:2.5rem;font-weight:700;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity))"
} : {
  name: "131f8jw-Home",
  styles: "margin-bottom:0.5rem;margin-top:2rem;text-align:center;font-size:2.25rem;line-height:2.5rem;font-weight:700;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref29 = process.env.NODE_ENV === "production" ? {
  name: "1d3w5wq",
  styles: "width:100%"
} : {
  name: "1c9tl6e-Home",
  styles: "width:100%;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref30 = process.env.NODE_ENV === "production" ? {
  name: "1n3apwf",
  styles: "margin-left:auto;margin-right:auto;margin-top:0px;margin-bottom:0px;height:0.25rem;width:33.333333%;border-top-left-radius:0.25rem;border-top-right-radius:0.25rem;padding-top:0px;padding-bottom:0px;opacity:0.25"
} : {
  name: "bxdf0l-Home",
  styles: "margin-left:auto;margin-right:auto;margin-top:0px;margin-bottom:0px;height:0.25rem;width:33.333333%;border-top-left-radius:0.25rem;border-top-right-radius:0.25rem;padding-top:0px;padding-bottom:0px;opacity:0.25;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref31 = process.env.NODE_ENV === "production" ? {
  name: "1fo50kt",
  styles: "margin-top:1.5rem"
} : {
  name: "1clyqo3-Home",
  styles: "margin-top:1.5rem;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function Home() {
  return _jsxs("div", {
    children: [_jsx(HeroSection, {}), _jsx("h2", {
      css: _ref,
      children: "Internet Corporativo y Residencial"
    }), _jsx("div", {
      css: _ref2,
      children: _jsx("div", {
        className: "gradient",
        css: _ref3
      })
    }), _jsxs("p", {
      css: _ref4,
      children: ["360NET, cuenta con una amplia y robusta red de enlaces microondas sobre t\xFAneles encriptados MPLS (conmutaci\xF3n de etiquetas muitiprotocolo), permiti\xE9ndonos as\xED, ofrecerles a nuestros clientes un abanico de servicios IP que tienen como punto de origen el ", _jsx("span", {
        css: _ref5,
        children: "NAP de las Am\xE9ricas."
      })]
    }), _jsxs("div", {
      css: _ref6,
      children: [_jsxs("div", {
        css: _ref7,
        children: [_jsx(Image, {
          css: _ref8,
          src: "/images/world.svg",
          width: 256,
          height: 256
        }), _jsx("h3", {
          css: _ref9,
          children: "Interconexiones"
        }), _jsx("p", {
          css: _ref10,
          children: "Conectado a los principales DataCenter de LATAM."
        })]
      }), _jsxs("div", {
        css: _ref11,
        children: [_jsx(Image, {
          css: _ref12,
          src: "/images/speedtest.svg",
          width: 256,
          height: 256
        }), _jsx("h3", {
          css: _ref13,
          children: "Hasta 10 Gbps"
        }), _jsx("p", {
          css: _ref14,
          children: "Nuestra red MPLS permite conmutaci\xF3n y transmisi\xF3n de datos, para nuestros clientes finales de hasta 10 Gbps, pudiendo evaluar opciones mayores, seg\xFAn el requerimiento."
        })]
      }), _jsxs("div", {
        css: _ref15,
        children: [_jsx(Image, {
          css: _ref16,
          src: "/images/gaming.svg",
          width: 256,
          height: 256
        }), _jsx("h3", {
          css: _ref17,
          children: "Sin interrupciones"
        }), _jsx("p", {
          css: _ref18,
          children: "Sin limites de descarga y con una latencia baja, podr\xE1s disfrutar de servicios de Streaming y juegos en linea"
        })]
      })]
    }), _jsxs("div", {
      css: _ref19,
      style: {
        backgroundImage: 'url("/images/coverage-map.jpg");'
      },
      children: [_jsx("div", {
        css: _ref20
      }), _jsx("div", {
        id: "cobertura",
        css: _ref21,
        children: _jsx("div", {
          css: _ref22,
          children: _jsxs("div", {
            css: _ref23,
            children: [_jsx("div", {
              css: _ref24,
              children: _jsx(SimpleSlider, {})
            }), _jsxs("div", {
              css: _ref25,
              children: [_jsx("span", {
                children: _jsx("i", {
                  className: "fas fa-wifi fa-5x"
                })
              }), _jsx("h1", {
                css: _ref26,
                children: "Mapa de cobertura y ubicaciones"
              }), _jsx("p", {
                css: _ref27,
                children: "Contamos con cobertura en las siguientes ciudades y estados: Cagua, Maracay, Morita, Palo Negro, Santa Rita, Turmero"
              })]
            })]
          })
        })
      })]
    }), _jsx("h2", {
      css: _ref28,
      children: "Prueba tu conexi\xF3n a Internet"
    }), _jsx("div", {
      css: _ref29,
      children: _jsx("div", {
        className: "gradient",
        css: _ref30
      })
    }), _jsx("iframe", {
      width: "100%",
      height: "550px",
      css: _ref31,
      frameborder: "0",
      src: "https://360netcustom.speedtestcustom.com"
    }), _jsx(PlanesSection, {}), _jsx(ContactoSection, {})]
  });
}