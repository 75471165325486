function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1dzuzjf",
  styles: "display:grid;width:100%;place-items:center;background-size:cover;background-position:center;> *{grid-column-start:1;grid-row-start:1;}position:relative;overflow:hidden"
} : {
  name: "6e9cp9-HeroSection",
  styles: "display:grid;width:100%;place-items:center;background-size:cover;background-position:center;> *{grid-column-start:1;grid-row-start:1;}position:relative;overflow:hidden;label:HeroSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "17zj75z",
  styles: "grid-column-start:1;grid-row-start:1;height:100%;width:100%;background-color:hsl(var(--n) / var(--tw-bg-opacity));--tw-bg-opacity:0.6"
} : {
  name: "cqjyy9-HeroSection",
  styles: "grid-column-start:1;grid-row-start:1;height:100%;width:100%;background-color:hsl(var(--n) / var(--tw-bg-opacity));--tw-bg-opacity:0.6;label:HeroSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "kj5j1j",
  styles: "z-index:10"
} : {
  name: "1i6x4n-HeroSection",
  styles: "z-index:10;label:HeroSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "miv5yw",
  styles: "position:absolute;inset:0px;z-index:0;width:100%"
} : {
  name: "gm5lwh-HeroSection",
  styles: "position:absolute;inset:0px;z-index:0;width:100%;label:HeroSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "djbii",
  styles: "z-index:0;display:flex;align-items:center;justify-content:center;max-width:80rem;gap:1rem;padding:1rem;position:relative;padding-top:2rem !important;padding-bottom:2rem !important;text-align:center;--tw-text-opacity:1;color:hsl(var(--nc) / var(--tw-text-opacity))"
} : {
  name: "gtilw3-HeroSection",
  styles: "z-index:0;display:flex;align-items:center;justify-content:center;max-width:80rem;gap:1rem;padding:1rem;position:relative;padding-top:2rem !important;padding-bottom:2rem !important;text-align:center;--tw-text-opacity:1;color:hsl(var(--nc) / var(--tw-text-opacity));label:HeroSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "5dtcix",
  styles: "max-width:32rem"
} : {
  name: "17vjr60-HeroSection",
  styles: "max-width:32rem;label:HeroSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "19gim6y",
  styles: "margin-bottom:1.25rem;font-size:3rem;line-height:1;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "es3rqz-HeroSection",
  styles: "margin-bottom:1.25rem;font-size:3rem;line-height:1;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:HeroSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "9kwmmn",
  styles: "margin-bottom:1.25rem"
} : {
  name: "182de1w-HeroSection",
  styles: "margin-bottom:1.25rem;label:HeroSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const HeroSection = () => {
  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    // await loadFull(engine);
  }, []);
  const particlesLoaded = useCallback(async container => {
    await console.log(container);
  }, []);
  return _jsx("div", {
    children: _jsxs("div", {
      css: _ref,
      style: {
        backgroundImage: 'url("/images/abstract.jpg")'
      },
      children: [_jsx("div", {
        css: _ref2
      }), _jsx("div", {
        className: "w wave",
        css: _ref3
      }), _jsx(Particles, {
        id: "tsparticles",
        css: _ref4,
        particlesLoaded: "particlesLoaded",
        style: {},
        options: {
          fpsLimit: 30,
          pauseOnOutsideViewport: true,
          fullScreen: {
            enable: false,
            zIndex: 99
          },
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push"
              },
              onHover: {
                enable: true,
                mode: "repulse"
              },
              resize: true
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40
              },
              push: {
                quantity: 4
              },
              repulse: {
                distance: 100,
                duration: 0.4
              }
            }
          },
          particles: {
            number: {
              density: {
                enable: true,
                area: 800,
                factor: 700
              }
            },
            links: {
              enable: true,
              distance: 90
            },
            color: {
              value: ["#aa73ff", "#f8c210", "#83d238", "#33b1f8"]
            },
            move: {
              enable: true,
              speed: 2,
              outModes: {
                default: "bounce"
              }
            },
            size: {
              value: 1
            }
          }
        },
        init: particlesInit,
        loaded: particlesLoaded
      }), _jsx("div", {
        css: _ref5,
        children: _jsxs("div", {
          css: _ref6,
          children: [_jsx("h1", {
            css: _ref7,
            children: "Prep\xE1rate para Internet de Alta Velocidad"
          }), _jsx("p", {
            css: _ref8,
            children: "\xBFQue esperas para contratar Internet de Fibra \xD3ptica? \xA1Navega a ULTRA ALTA VELOCIDAD"
          })]
        })
      })]
    })
  });
};
export default HeroSection;